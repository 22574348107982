import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.scss';
import '@/assets/theme.min.css';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Dock from 'primevue/dock';
import MegaMenu from 'primevue/megamenu';
import Tooltip from 'primevue/tooltip';
import router from './router';

const app = createApp(App).use(router);
app.use(PrimeVue);
app.component('PButton', Button);
app.component('PDialog', Dialog);
app.component('PDock', Dock);
app.component('PCard', Card);
app.component('PTopBar', MegaMenu);
app.directive('tooltip', Tooltip);
app.mount('#app');
