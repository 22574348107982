import { IDockItem } from './IDockItem';

export const binDockItem = {
  title: 'Prullenbak',
  text: 'Prullenbak',
  icon: './dock-icons/prullenbak.png'
} as IDockItem;

export const homeDockItem = {
  title: '3B - Remedial teaching',
  text: `Welkom bij de praktijk voor Remedial Teaching in de 3B-Hoek
  

Online lessen zijn ook mogelijk!`,
  icon: './dock-icons/prullenbak.png'
} as IDockItem;

export const dockItems = [{
  title: 'Over mij',
  icon: './dock-icons/over-mij.png',
  text: `Mijn naam is Ineke Coelers. Sinds 2005 ben ik werkzaam in mijn eigen praktijk. Daarnaast heb ik tot 2022 in deeltijd gewerkt als intern begeleider en remedial teacher op diverse Jenaplan scholen.

Als gediplomeerd leerkracht, intern begeleider en remedial teacher heb ik jarenlang ervaring met het geven van begeleiding, zowel groepsgewijs als individueel. Door het volgen van cursussen, trainingen en zelfstudie blijf ik op de hoogte van de laatste ontwikkelingen. Ook ben ik lid van de LBRT, de landelijke beroepsvereniging voor remedial teachers.

Een van de uitgangspunten van de begeleiding is het welbevinden van de kinderen. Het vergroten van het zelfvertrouwen en het vertrouwen op hun eigen kracht is hier een onderdeel van. De kinderen worden betrokken bij de lessen en er wordt ingespeeld op de natuurlijke behoefte van kinderen om te leren.

Naast mijn opleiding als intern begeleider en remedial teacher heb ik nascholingen gevolgd op een groot aantal gebieden.

Voorbeelden van trainingen:
  •  NLP practitioner
  •  NLP en remedial teaching
  •  NLsPel
  •  Matrixcoaching: dyslexie anders bekeken
  •  Faalangstreductie trainer
  •  Train de Trainer; vriendenprogramma
  •  Meesterlijk coachen

Voorbeelden van nascholingen:
  •  Sociaal emotionele problematiek binnen de remediale hulp
  •  Testgebruik voor onderwijsgevenden
  •  Begrijpend lezen: blijvend leesresultaat
  •  Dyslexie
  •  Dyscalculie
  •  Hoogbegaafdheid
  •  Effectief spellingsonderwijs
  •  Spelenderwijs Engels leren
  `
} as IDockItem,
{
  title: 'Wat is Remedial Teaching',
  icon: './dock-icons/remedial-teaching.png',
  text: `Geen kind is hetzelfde. Sommige kinderen zijn goed in taal, anderen zijn juist weer heel goed in rekenen.

Bij sommige kinderen gaat het leren minder makkelijk dan verwacht. Er kan dan een probleem zijn met een bepaald vak, soms is er sprake van verschillende leer- en/of ontwikkelingsproblemen. Remedial teaching kan in dat geval hulp bieden. Een kind krijgt extra ondersteuning op het gebied waar hij problemen mee heeft, waardoor het leren hem makkelijker afgaat en het zelfvertrouwen groeit.
  
RT staat voor remedial teaching, er wordt hulp verleend aan leerlingen die op pedagogisch en/ of didactisch gebied hulp nodig hebben. Bij remedial teaching gaat het om het toepassen van gespecialiseerde behandelingstechnieken. Deze technieken gaan uit van de specifieke problemen van een kind en zijn gericht op het oplossen of verminderen van die problemen. Deze hulp is afgestemd op het kind, het is maatwerk.
  
Remedial teaching is geschikt als u bijvoorbeeld het idee heeft dat u het idee heeft dat niet alle kwaliteiten van uw kind eruit komen of als u merkt dat de ontwikkeling van uw kind stagneert.
  `
} as IDockItem,
{
  title: 'Werkwijze',
  icon: './dock-icons/werkwijze.png',
  text: `Nadat u uw kind hebt aangemeld, maken wij een afspraak voor een intakegesprek. Tijdens dit gesprek zal ik zoveel mogelijk relevante gegevens met u bespreken. Zeer handig is het, als u tijdens het intake-gesprek alvast gegevens van school meeneemt. In principe wordt er een didactisch onderzoek afgenomen om een completer beeld te vormen.

Soms kan het handig zijn om contact op te nemen met de leerkracht van uw kind. Dit gebeurt alleen met uw toestemming. Leerkrachten kunnen informatie geven over de gebruikte leermethodes en het gedrag van uw kind op school. Dit kan een completer beeld geven van uw kind. Op deze manier kan de hulp aan uw kind in de klas en tijdens de remedial teaching goed op elkaar afgestemd worden en kan er een optimale begeleiding plaatsvinden. 

Onderzoek: 
Het afnemen van een volledig didactisch onderzoek duurt ongeveer twee uur. Om te weten te komen welke begeleiding het meest passend is, worden diverse toetsen en testen bij uw kind afgenomen. Daarnaast geven gesprekjes met uw kind belangrijke informatie. 

Begeleidingsplan: 
Aan de hand van alle gegevens wordt een begeleidingsplan opgesteld, dat met u besproken wordt. Daarnaast wordt uiteraard ook de verwachte tijdsduur van de begeleiding hierin meegenomen. 
  
Begeleiding: 
De begeleiding vindt altijd plaats op een vast tijdstip, tijdens de begeleiding werken we concreet aan het verbeteren van de vaardigheden. Soms zal een kind hiervoor huiswerk meekrijgen. Door een op uw kind afgestemde aanpak, krijgt hij of zij de kans succeservaringen op te doen. Zijn/haar zelfvertrouwen, motivatie en plezier in het leren zullen hierdoor groeien. 

Evaluatie: 
Hierin zullen we door de onderzoeksresultaten lopen en deze evalueren. Na de begeleiding zal, op basis van de voortgang, samen met u worden besloten of een vervolg nodig is. 


Onderzoek en begeleiding kunnen plaatsvinden bij mij in de praktijk of online.
`
} as IDockItem,
{
  title: 'Gebieden',
  icon: './dock-icons/gebieden.png',
  text: `De praktijk biedt begeleiding bij:

Taal (o.a.):
  •  Technisch lezen
  •  Begrijpend en studerend lezen
  •  Nederlands als tweede taal
  •  Spelling
  •  Ontleden
  •  Schrijfvaardigheden (waaronder opbouw van een tekst.)

Rekenen en wiskunde (o.a.):
  •  Basisvaardigheden
  •  Meten, tijd en geld
  •  Procenten en verhoudingen
  •  Breuken en kommagetallen
  •  Grafieken en tabellen

Overige:
  •  Schrijfmotoriek
  •  Studievaardigheden
  •  Concentratie en werkhoudingsproblemen
  •  Plannen en leren “leren”
 
Het is mogelijk begeleiding te krijgen ter voorbereiding op de doorstroomtoets in het basisonderwijs. De kinderen kunnen dan wennen aan de vraagstelling en met meer zelfvertrouwen beginnen aan de toets.

De praktijk biedt, naast begeleiding aan leerlingen uit het primair onderwijs, ook begeleiding aan leerlingen van het voortgezet onderwijs die moeite hebben met bijvoorbeeld plannen, organiseren en overzicht houden of de manier van leren. Ook kan er begeleiding gegeven worden bij problemen op het gebied van wiskunde, Nederlands, Frans en Engels.  
  `
} as IDockItem,
{
  title: 'Tarieven',
  icon: './dock-icons/wallet.png',
  text: `U kunt vrijblijvend een afspraak maken om uw vraag te bespreken.

Uurtarief individuele begeleiding, begeleiding op school / thuis: € 50,00

  •  Onderzoek, inclusief begeleidingsplan, voor- en nagesprek kosten uurtarief.
  •  Voor het ontwikkelen van materialen wordt het uurtarief in rekening gebracht
  •  Gesprekken met school: uurtarief + reiskosten
  •  De betaling vindt achteraf plaats, u ontvangt 1 keer per maand een factuur hiervoor.
  •  Afspraken dienen 24 uur van te voren afgezegd te worden, anders wordt de afspraak in rekening gebracht.
  `
} as IDockItem,
{
  title: 'Contact',
  icon: './dock-icons/contact.png',
  text: `Praktijk voor Remedial Teaching
C.M. Coelers
Berkel & Rodenrijs

E-mail:   cmcoelers@3b-remedialteaching.nl
Telefoon: +31 (0)6 44 99 66 06`
} as IDockItem
];
