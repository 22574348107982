
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Container from './Container.vue';
import { binDockItem, dockItems, homeDockItem } from './../models/DockItems';
import { IDockItem } from '../models/IDockItem';

@Options({
  components: {
    Container
  },
  props: {
    topBarMessage: String
  }
})
export default class Site extends Vue {
  private topBarMessage!: string;
  private dialogVisible = false;
  private itemsInTheBin = false;
  public dockItems = ref([...dockItems, binDockItem]);
  private currentItem: IDockItem | null = null;

  private onDockItemClick (event: MouseEvent, item: IDockItem): void {
    if (item != null && item !== this.currentItem && item.title !== binDockItem.title) {
      this.currentItem = item;
      const containerRef = this.$refs.container as any;
      if (containerRef != null) {
        containerRef.$el.querySelector('.p-card-content').scrollTop = 0;
      }
    }

    if (item.title === binDockItem.title && this.itemsInTheBin) {
      this.dialogVisible = true;
    }

    event.preventDefault();
  }

  private cleanTrash () {
    this.setBinDockItem('./dock-icons/prullenbak.png');
    this.dialogVisible = false;
    this.itemsInTheBin = false;
  }

  private closeContainer () {
    if (this.currentItem != null) {
      this.moveContainerToBin();
      setTimeout(() => {
        this.setBinDockItem('./dock-icons/prullenbak-vol.png');
        this.currentItem = null;
        this.itemsInTheBin = true;
      }, 400);
    }
  }

  private moveContainerToBin () {
    const binElement = this.$refs.Prullenbak as any;
    const containerRef = this.$refs.container as any;

    if (binElement == null || containerRef == null || containerRef.$el == null) {
      return;
    }

    const containerElement = containerRef.$el;
    const containerRect = containerElement.getBoundingClientRect();
    containerElement.style.left = containerRect.left + 'px';
    containerElement.style.top = containerRect.top + 'px';

    const binRect = binElement.getBoundingClientRect();
    containerElement.style.position = 'absolute';
    containerElement.style.left = ((binRect.left + binRect.right) / 2.0) + 'px';
    containerElement.style.top = ((binRect.top + binRect.bottom) / 2.0) + 'px';
    containerElement.style.width = '10px';
    containerElement.style.height = '10px';
    containerElement.style.transition = 'width 0.4s, height 0.4s, left 0.4s, top 0.4s';
    setTimeout(() => {
      containerElement.querySelector('.p-card-body').style.height = '10px';
      containerElement.querySelector('.p-card-content').style.height = '10px';
    }, 100);
  }

  private setBinDockItem (icon: string) {
    const dockItemArray = (this.dockItems as any);
    const localBinDockItem = dockItemArray[dockItemArray.length - 1];
    localBinDockItem.icon = icon;
  }
}
