
import { Options, Vue } from 'vue-class-component';
import Site from './components/Site.vue';
import SiteMobile from './components/SiteMobile.vue';

@Options({
  components: {
    Site,
    SiteMobile
  }
})
export default class App extends Vue {
  private isMobile = false;
  private topBarMessage = 'Welkom bij de praktijk voor Remedial Teaching in de 3B-Hoek';

  public mounted (): void {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  }

  public beforeDestroy (): void {
    window?.removeEventListener('resize', this.onResize);
  }

  private onResize () {
    this.isMobile = window.innerWidth < 768;
  }
}
