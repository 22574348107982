
import { ref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import ContainerMobile from './ContainerMobile.vue';
import { dockItems, homeDockItem } from './../models/DockItems';
import { IDockItem } from './../models/IDockItem';

@Options({
  components: {
    ContainerMobile
  },
  props: {
    topBarMessage: String
  }
})
export default class SiteMobile extends Vue {
  private topBarMessage!: string;
  private dockItems = ref(dockItems);
  private currentItem: IDockItem = homeDockItem;

  private onDockItemClick (event: MouseEvent, item: IDockItem): void {
    if (item != null && item !== this.currentItem) {
      this.currentItem = item;
      const containerRef = this.$refs.container as any;
      if (containerRef != null) {
        containerRef.$el.querySelector('.p-card-content').scrollTop = 0;
      }
    }
    event.preventDefault();
  }
}
