
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String,
    text: String
  }
})
export default class ContainerMobile extends Vue {
  private title!: string;
  private text!: string;
}
