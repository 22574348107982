import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_site = _resolveComponent("site")!
  const _component_site_mobile = _resolveComponent("site-mobile")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_site, {
          key: 0,
          topBarMessage: _ctx.topBarMessage
        }, null, 8, ["topBarMessage"]))
      : (_openBlock(), _createBlock(_component_site_mobile, {
          key: 1,
          topBarMessage: _ctx.topBarMessage
        }, null, 8, ["topBarMessage"]))
  ]))
}