import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-site-mobile" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_container_mobile = _resolveComponent("container-mobile")!
  const _component_p_dock = _resolveComponent("p-dock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentItem != null)
      ? (_openBlock(), _createBlock(_component_container_mobile, {
          key: 0,
          title: _ctx.currentItem.title,
          text: _ctx.currentItem.text,
          ref: "container"
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    _createVNode(_component_p_dock, {
      model: _ctx.dockItems,
      class: "dock-custom mb-2"
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("div", {
          class: "p-dock-link",
          onClick: ($event: any) => (_ctx.onDockItemClick($event, item))
        }, [
          _createElementVNode("img", {
            alt: item.title,
            src: item.icon,
            style: {"width":"100%"}
          }, null, 8, _hoisted_3)
        ], 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}