import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-primary text-base card-title-custom" }
const _hoisted_2 = { class: "ml-2 mr-2" }
const _hoisted_3 = { class: "ml-2 mr-2 my-0 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_p_card = _resolveComponent("p-card")!

  return (_openBlock(), _createBlock(_component_p_card, { class: "p-card-mobile" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }))
}